<template>
  <a v-bind:href="href" class="rounded-circle bg-white">
    <i v-bind:class="[`fa-${icon}`, 'fab', 'dark-rose-text']" />
  </a>
</template>
<script>
export default {
  name: 'SocialIcon',
  props: {
    href: {
      type: String,
      default: "#",
      description: 'Link in social media'
    },
    icon: {
      type: String,
      default: "",
      description: 'Button icon'
    }
  },
  data() {
    return {}
  },

}
</script>
<style lang="scss">
.rounded-circle {
  padding: 0;
  margin-left: 2px;
  margin-right: 2px;
  width: 38px;
  height: 38px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;

  i {
    font-size: 1.2em;
  }
}
</style>
