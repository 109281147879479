<template>
  <section class="section section-second-categories-report">
    <div class="sticky-top py-3" ref="sticky-categories">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-1" />

          <div class="col-md-4">
            <p class="px-4 px-md-0 bold-text mb-0" style="color: #2f2f6b">
              ¿Qué información se publica de estos procesos en los diferentes ámbitos del sistema judicial?
            </p>
          </div>
          <div class="col-md-6">
            <ul class="category-buttons mb-0">
              <li v-for="category in categories" :key="category.id" @click="scrollToCategory(category.id)" v-bind:class="[
                `category-${category.id}` === categoryActive ? 'active' : '',
                `btn-category-${category.id}`,
                'bold-text px-1 py-2 px-md-3 py-md-3 text-uppercase',
              ]">
                <span>{{ category.name }}</span>
              </li>
            </ul>
          </div>
          <div class="col-md-1" />
        </div>
      </div>
    </div>

    <div class="container-fluid" v-for="(category, categoryIndex) in categories" :key="category.id"
      :id="`category-${category.id}`" ref="category">
      <div v-for="(section, sectionIndex) in category.sections" :key="section.name"
        v-bind:class="[getRowClass(categoryIndex, sectionIndex), 'row my-5']">
        <div class="col-md-1" />
        <div class="col-12 col-md">
          <p class="px-3 text-uppercase bold-text dark-rose-text">{{ category.name }}</p>
          <h3 class="px-3 text-uppercase bold-text dark-rose-text">{{ section.name }}</h3>

          <collapse :active-index="section.questionIndexSelected" :multipleActive="false">
            <collapse-item v-for="(question, questionIndex) in section.questions" :key="question.id">
              <template v-slot:title="{ active }">
                <div class="d-flex align-items-center px-3 collapse-title"
                  v-on:click="selectQuestion(section, questionIndex)">
                  <span :class="[
                    'flex-grow-1',
                    'regular-text',
                    'medium-large-text',
                    { 'text-black': !active, 'dark-rose-text': active },
                  ]">
                    {{ question.title }}
                  </span>
                  <i class="fa fa-caret-down" style="font-size: 2em; color: var(--dark-rose-color)" />
                </div>
              </template>
              <div class="show-on-mobile mb-4">
                <video-custom slot="video" :video-src="getVideoPath(category.id, section)" />
              </div>
            </collapse-item>
          </collapse>
        </div>

        <div class="d-md-block col-md d-none">
          <video-custom :video-src="getVideoPath(category.id, section)" />
        </div>
        <div class="col-md-1" />
      </div>
    </div>
  </section>
</template>

<script>
import { SectionTitle, VideoCustom, Collapse, CollapseItem } from "@/components";

export default {
  name: "SecondReportCategoriesSection",
  components: {
    SectionTitle,
    VideoCustom,
    Collapse,
    CollapseItem,
  },
  data() {
    return {
      categoryActive: "category-jueces",
      categories: [
        {
          name: "Jueces y juezas",
          id: "jueces",
          active: true,
          sections: [
            {
              name: "Ingreso y selección",
              questionIndexSelected: 0,
              questions: [
                {
                  id: 1,
                  title:
                    "¿Qué países publican las “reglas de juego” (normativa) de los concursos?",
                },
                {
                  id: 2,
                  title:
                    "¿Qué países publican las convocatorias a concursos o a procesos de postulación para la selección de jueces y juezas de menor instancia?",
                },
                {
                  id: 3,
                  title:
                    "¿Qué países publican las hojas de vida de los y las postulantes?",
                },
                {
                  id: 4,
                  title:
                    "¿Qué países publican las relaciones empresariales de las y los postulantes?",
                },
                {
                  id: 5,
                  title:
                    "¿Cuáles son los órganos rectores en los procesos selección de jueces y juezas de tribunales inferiores?",
                },
                {
                  id: 6,
                  title:
                    "¿Se publican los resultados del concurso para ejercer como juez o jueza?",
                },
              ],
            },
            {
              name: "Seguimiento y control del desempeño",
              questionIndexSelected: 0,
              questions: [
                {
                  id: 7,
                  title:
                    "¿Cuáles son los órganos rectores en los procesos de evaluación de desempeño de jueces y juezas de tribunales inferiores?",
                },
                {
                  id: 8,
                  title:
                    "¿Qué países realizan evaluaciones de desempeño anual a los jueces y juezas de tribunales inferiores?",
                },
                {
                  id: 9,
                  title:
                    "¿Qué países publican los resultados de las evaluaciones de los jueces y juezas?",
                },
              ],
            },
            {
              name: "Sanción y remoción",
              questionIndexSelected: 0,
              questions: [
                {
                  id: 10,
                  title:
                    "¿Cuál es el órgano rector de los procesos disciplinarios hacia jueces y juezas de tribunales inferiores?",
                },
                {
                  id: 11,
                  title:
                    "¿Qué países de la región publican la información de los procesos disciplinarios abiertos y los resultados de estos?",
                },
                {
                  id: 12,
                  title:
                    "¿Cuáles son los órganos rectores en los procesos de remoción de jueces y juezas de tribunales inferiores?",
                },
              ],
            },
          ],
        },
        {
          name: "Fiscales",
          id: "fiscales",
          active: false,
          sections: [
            {
              name: "Ingreso y selección",
              questionIndexSelected: 0,
              questions: [
                {
                  id: 1,
                  title:
                    "¿Qué países publican las “reglas de juego” (normativa) de los concursos?",
                },
                {
                  id: 2,
                  title:
                    "¿Qué países publican las convocatorias a concursos o procesos de postulación para la selección de fiscales?",
                },
                {
                  id: 3,
                  title:
                    "¿Qué países publican las hojas de vida de los y las postulantes?",
                },
                {
                  id: 4,
                  title:
                    "¿Qué países publican las relaciones empresariales de las y los postulantes?",
                },
                {
                  id: 5,
                  title:
                    "¿Cuáles son los órganos rectores en los procesos de selección de fiscales de menor instancia?",
                },
                {
                  id: 6,
                  title:
                    "¿Se publican los resultados del concurso para ejercer como fiscal?",
                },
              ],
            },
            {
              name: "Seguimiento y control del desempeño",
              questionIndexSelected: 0,
              questions: [
                {
                  id: 7,
                  title:
                    "¿Cuáles son los órganos rectores en los procesos de evaluación de desempeño de fiscales de menor instancia?",
                },
                {
                  id: 8,
                  title:
                    "¿Qué países realizan evaluaciones de desempeño anual a las y los fiscales?",
                },
                {
                  id: 9,
                  title:
                    "¿Qué países publican los resultados de las evaluaciones de las y los fiscales?",
                },
              ],
            },
            {
              name: "Sanción y remoción",
              questionIndexSelected: 0,
              questions: [
                {
                  id: 10,
                  title:
                    "¿Cuáles son los órganos rectores en los  procesos disciplinarios a fiscales de menor instancia?",
                },
                {
                  id: 11,
                  title:
                    "¿Qué países de la región publican la información de los procesos disciplinarios abiertos y los resultados de estos?",
                },
                {
                  id: 12,
                  title:
                    "¿Cuáles son los órganos rectores en los procesos de remoción de fiscales?",
                },
              ],
            },
          ],
        },
        {
          name: "Defensores y defensoras",
          id: "defensores",
          active: false,
          sections: [
            {
              name: "Ingreso y selección",
              questionIndexSelected: 0,
              questions: [
                {
                  id: 1,
                  title:
                    "¿Qué países publican las “reglas de juego” (normativa) de los concursos?",
                },
                {
                  id: 2,
                  title:
                    "¿Qué países publican las convocatorias a concursos o procesos de postulación para la selección de defensoras y defensores públicos?",
                },
                {
                  id: 3,
                  title:
                    "¿Qué países publican las hojas de vida de los y las postulantes?",
                },
                {
                  id: 4,
                  title:
                    "¿Qué países publican las relaciones empresariales de las y  los postulantes?",
                },
                {
                  id: 5,
                  title:
                    "¿Cuáles son los órganos rectores en los procesos de selección de defensores y defensoras de menor instancia?",
                },
                {
                  id: 6,
                  title:
                    "¿Se publican los resultados del concurso para ejercer como defensor/a público?",
                },
              ],
            },
            {
              name: "Seguimiento y control del desempeño",
              questionIndexSelected: 0,
              questions: [
                {
                  id: 7,
                  title:
                    "¿Cuáles son los órganos rectores en los procesos de evaluación de desempeño de defensores y defensoras de menor instancia?",
                },
                {
                  id: 8,
                  title:
                    "¿Qué países realizan evaluaciones de desempeño anual a los defensores y defensoras?",
                },
                {
                  id: 9,
                  title:
                    "¿Qué países publican los resultados de las evaluaciones de las y los defensores ?",
                },
              ],
            },
            {
              name: "Sanción y remoción",
              questionIndexSelected: 0,
              questions: [
                {
                  id: 10,
                  title:
                    "¿Cuál es el órgano rector de los procesos disciplinarios hacia defensores y defensoras de menor instancia?",
                },
                {
                  id: 11,
                  title:
                    "¿Qué países de la región publican la información de los procesos disciplinarios abiertos y los resultados de estos?",
                },
                {
                  id: 12,
                  title:
                    "¿Cuáles son los órganos rectores en los procesos de remoción de las y los  defensores?",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.createIntersectionObserver();
  },
  methods: {
    getRowClass(categoryIndex, sectionIndex) {
      let temp = 1;
      if (sectionIndex % 2 != 0) {
        temp = -1;
      }
      if (categoryIndex % 2 != 0) temp *= -1;

      return temp < 0 ? "flex-md-row-reverse" : "flex-md-row";
    },
    getVideoPath(id, section) {
      const { questionIndexSelected } = section;
      const question = section.questions[questionIndexSelected];
      return `img/resources/second-report/${id.toLowerCase()}_${question.id}.mp4`;
    },
    selectQuestion(section, index) {
      section.questionIndexSelected = index;
    },
    createIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.categoryActive = entry.target.id;
          }
        });
      }, options);

      this.$refs.category.forEach((element) => {
        observer.observe(element);
      });
    },
    scrollToCategory(categoryId) {
      const categoryElement = this.$refs.category.find(
        (element) => element.id === `category-${categoryId}`
      );

      if (categoryElement) {
        const sticky = this.$refs["sticky-categories"];
        const offset = sticky ? sticky.offsetHeight + 25 : 0;
        const elementPosition =
          categoryElement.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sticky-top {
  background-color: #E7E7F7;
}

.category-buttons {
  display: flex;
  gap: 0.2em;

  li {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #991d44;
    color: white;
    cursor: pointer;

    &.active,
    &:hover {
      color: #2F2F6B;

      &.btn-category-jueces {
        background-color: #B8B8FA;
      }

      &.btn-category-fiscales {
        background-color: #E2F4F3;
      }

      &.btn-category-defensores {
        background-color: #74B3AB;
      }
    }
  }
}

.show-on-mobile {
  display: none;
}

@media (max-width: 768px) {
  .show-on-mobile {
    display: block;
  }
}
</style>
