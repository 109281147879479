var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section section-first-report"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"justify-content-end align-items-center row title"},[_c('div',{staticClass:"col-md-1"}),_c('div',{staticClass:"col-md-4"},[_c('section-title',{attrs:{"title":"Informe I","text-color":"#74B3AB"}}),_vm._m(0)],1),_vm._m(1)]),_c('div',{staticClass:"justify-content-end row"},[_c('div',{staticClass:"col-md-1"}),_c('div',{staticClass:"col-md-4"},[_c('collapse',{attrs:{"active-index":_vm.indexImage,"multipleActive":false}},[_c('collapse-item',{scopedSlots:_vm._u([{key:"title",fn:function(ref){
var active = ref.active;
return [_c('div',{staticClass:"d-flex collapse-title"},[_c('span',{staticClass:"flex-grow-1",class:[
                  'regular-text',
                  'medium-large-text',
                  { 'text-black': !active, 'dark-rose-text': active } ],staticStyle:{"display":"block"},on:{"click":function($event){_vm.indexImage = 0}}},[_vm._v("¿Cómo se organizan y de qué modo contactarlas?")]),_c('i',{staticClass:"fa fa-caret-down",staticStyle:{"font-size":"2em","color":"var(--dark-rose-color)"},on:{"click":function($event){_vm.indexImage = 0}}})])]}}])},[_c('div',{staticClass:"medium-text text-black regular-text"},[_c('p',{staticClass:"collapse-text"},[_vm._v(" Conocer cómo se organizan las agencias y unidades del sistema, quiénes toman las decisiones, de qué asuntos se ocupan, o cuáles son las vías para contactarles, resulta elemental para que la ciudadanía pueda hacer uso del servicio de justicia. ")]),_c('img',{staticClass:"show-on-mobile mb-4",attrs:{"slot":"image","src":_vm.images[0].src},slot:"image"})])]),_c('collapse-item',{scopedSlots:_vm._u([{key:"title",fn:function(ref){
                var active = ref.active;
return [_c('div',{staticClass:"d-flex collapse-title"},[_c('span',{staticClass:"flex-grow-1",class:[
                  'regular-text',
                  'medium-large-text',
                  { 'text-black': !active, 'dark-rose-text': active } ],staticStyle:{"display":"block"},on:{"click":function($event){_vm.indexImage = 1}}},[_vm._v("¿Con qué fondos cuentan y en qué los invierten? ")]),_c('i',{staticClass:"fa fa-caret-down",staticStyle:{"font-size":"2em","color":"var(--dark-rose-color)"},on:{"click":function($event){_vm.indexImage = 1}}})])]}}])},[_c('div',{staticClass:"medium-text text-black regular-text"},[_c('p',{staticClass:"collapse-text"},[_vm._v(" La información presupuestaria permite conocer la inversión pública en el sistema de justicia y el modo en que esta se distribuye. Sin embargo, el nivel de opacidad en este campo es muy elevado. ")]),_c('img',{staticClass:"show-on-mobile mb-4",attrs:{"slot":"image","src":_vm.images[1].src},slot:"image"})])]),_c('collapse-item',{scopedSlots:_vm._u([{key:"title",fn:function(ref){
                var active = ref.active;
return [_c('div',{staticClass:"d-flex collapse-title"},[_c('span',{staticClass:"flex-grow-1",class:[
                  'regular-text',
                  'medium-large-text',
                  { 'text-black': !active, 'dark-rose-text': active } ],staticStyle:{"display":"block"},on:{"click":function($event){_vm.indexImage = 2}}},[_vm._v("¿Qué y cómo gestionan?")]),_c('i',{staticClass:"fa fa-caret-down",staticStyle:{"font-size":"2em","color":"var(--dark-rose-color)"},on:{"click":function($event){_vm.indexImage = 2}}})])]}}])},[_c('div',{staticClass:"medium-text text-black regular-text"},[_c('p',{staticClass:"collapse-text"},[_vm._v(" La información relativa a la gestión y el seguimiento de expedientes permite construir una idea sobre la eficiencia y pertinencia del trabajo que realiza la justicia. Asimismo, conocer los asuntos que se tramitan permite comprender la conflictividad social y procesos como la judicialización de la política. Sin embargo, en la región son muy limitadas las oportunidades para acceder a ese tipo de información. ")]),_c('img',{staticClass:"show-on-mobile mb-4",attrs:{"slot":"image","src":_vm.images[2].src},slot:"image"})])]),_c('collapse-item',{scopedSlots:_vm._u([{key:"title",fn:function(ref){
                var active = ref.active;
return [_c('div',{staticClass:"d-flex collapse-title"},[_c('span',{staticClass:"flex-grow-1",class:[
                  'regular-text',
                  'medium-large-text',
                  { 'text-black': !active, 'dark-rose-text': active } ],staticStyle:{"display":"block"},on:{"click":function($event){_vm.indexImage = 3}}},[_vm._v("¿Qué asuntos resuelven y cómo lo hacen? ")]),_c('i',{staticClass:"fa fa-caret-down",staticStyle:{"font-size":"2em","color":"var(--dark-rose-color)"},on:{"click":function($event){_vm.indexImage = 3}}})])]}}])},[_c('div',{staticClass:"medium-text text-black regular-text"},[_c('p',{staticClass:"collapse-text"},[_vm._v(" Difundir sus decisiones e informar el modo en que contribuye a la gestión de los conflictos, debe ser una prioridad para cualquier sistema de justicia, especialmente en aquellos asuntos de evidente interés público. No obstante, esto no se verifica en la región. ")]),_c('img',{staticClass:"show-on-mobile mb-4",attrs:{"slot":"image","src":_vm.images[3].src},slot:"image"})])]),_c('collapse-item',{scopedSlots:_vm._u([{key:"title",fn:function(ref){
                var active = ref.active;
return [_c('div',{staticClass:"d-flex collapse-title"},[_c('span',{staticClass:"flex-grow-1",class:[
                  'regular-text',
                  'medium-large-text',
                  { 'text-black': !active, 'dark-rose-text': active } ],staticStyle:{"display":"block"},on:{"click":function($event){_vm.indexImage = 4}}},[_vm._v("¿Quién selecciona a jueces y juezas de las cortes superiores? ")]),_c('i',{staticClass:"fa fa-caret-down",staticStyle:{"font-size":"2em","color":"var(--dark-rose-color)"},on:{"click":function($event){_vm.indexImage = 4}}})])]}}])},[_c('div',{staticClass:"medium-text text-black regular-text"},[_c('p',{staticClass:"collapse-text"},[_vm._v(" Conocer quién y con qué criterios seleccionan a las personas que integran los máximos tribunales nacionales / federales, es fundamental para construir ideas informadas, por ejemplo, sobre las posibilidades y límites de su independencia. Pero este tema también es muy ambiguo en la región. ")]),_c('img',{staticClass:"show-on-mobile mb-4",attrs:{"slot":"image","src":_vm.images[4].src},slot:"image"})])]),_c('collapse-item',{scopedSlots:_vm._u([{key:"title",fn:function(ref){
                var active = ref.active;
return [_c('div',{staticClass:"d-flex collapse-title"},[_c('span',{staticClass:"flex-grow-1",class:[
                  'regular-text',
                  'medium-large-text',
                  { 'text-black': !active, 'dark-rose-text': active } ],staticStyle:{"display":"block"},on:{"click":function($event){_vm.indexImage = 5}}},[_vm._v("¿Cómo es el desempeño de quienes toman decisiones en los sistemas de justicia?")]),_c('i',{staticClass:"fa fa-caret-down",staticStyle:{"font-size":"2em","color":"var(--dark-rose-color)"},on:{"click":function($event){_vm.indexImage = 5}}})])]}}])},[_c('div',{staticClass:"medium-text text-black regular-text"},[_c('p',{staticClass:"collapse-text"},[_vm._v(" Analizar la performance de las personas que toman decisiones dentro del sistema judicial, permite al Estado y a la ciudadanía conocer su calidad profesional e impulsar políticas de fortalecimiento y mejora para alcanzar metas institucionales. Sin embargo, en la región son extremadamente limitadas las prácticas de evaluación del conocimiento y/o desempeño de estas personas. ")]),_c('img',{staticClass:"show-on-mobile mb-4",attrs:{"slot":"image","src":_vm.images[5].src},slot:"image"})])]),_c('collapse-item',{scopedSlots:_vm._u([{key:"title",fn:function(ref){
                var active = ref.active;
return [_c('div',{staticClass:"d-flex collapse-title"},[_c('span',{staticClass:"flex-grow-1",class:[
                  'regular-text',
                  'medium-large-text',
                  { 'text-black': !active, 'dark-rose-text': active } ],staticStyle:{"display":"block"},on:{"click":function($event){_vm.indexImage = 6}}},[_vm._v("¿Quiénes y cómo remueven a las personas decisoras?")]),_c('i',{staticClass:"fa fa-caret-down",staticStyle:{"font-size":"2em","color":"var(--dark-rose-color)"},on:{"click":function($event){_vm.indexImage = 6}}})])]}}])},[_c('div',{staticClass:"medium-text text-black regular-text"},[_c('p',{staticClass:"collapse-text"},[_vm._v(" Si una persona quisiera impulsar o conocer el estado de trámite de un proceso para remover magistrados, deberá recibir orientación de algún o alguna experta debido a que la información pública es extremadamente limitada. ")]),_c('img',{staticClass:"show-on-mobile mb-4",attrs:{"slot":"image","src":_vm.images[6].src},slot:"image"})])])],1)],1),_c('div',{staticClass:"d-md-block mt-4 mt-lg-0 col-md-7 d-none",staticStyle:{"padding":"0px"}},[_c('div',{directives:[{name:"sticky",rawName:"v-sticky",value:({ zIndex: 10, stickyTop: 10, disabled: false }),expression:"{ zIndex: 10, stickyTop: 10, disabled: false }"}]},[_c('img',{staticStyle:{"max-height":"100vh","margin":"0px 10px"},attrs:{"slot":"image","alt":_vm.images[_vm.indexImage].title,"title":_vm.images[_vm.indexImage].title,"src":_vm.images[_vm.indexImage].src},slot:"image"})])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"gray-text large-text regular-text"},[_vm._v(" En "),_c('span',{staticClass:"medium-green-text super-bold-text"},[_vm._v("\"Saber+: "),_c('br')]),_c('span',{staticClass:"medium-green-text bold-text"},[_vm._v("Opacidad en los sistemas judiciales de la región\" ")]),_vm._v(" repasamos el estado de la transparencia activa en relación a varios aspectos: ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-7"},[_c('p',{staticClass:"dark-rose-text regular-text",staticStyle:{"max-width":"810px"}},[_vm._v(" La región presenta niveles de opacidad extremos en relación a la gestión y desempeño del sistema de justicia, sus decisores/as y operadores/as. Esta falta de información sostiene la cultura histórica del secretismo judicial y refuerza el alto grado de desconfianza ciudadana. ")])])}]

export { render, staticRenderFns }