import Vue from "vue";
import Router from "vue-router";
import Landing from "./pages/Landing.vue";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      name: "home",
      components: {default: Landing},
      // props: {
      //   footer: {backgroundColor: 'black'},
      //   header: {colorOnScroll: 65}
      // }
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
});

export default router;
