<template>
  <div class="section-title-container" v-bind:style="{ color: textColor }">
    <h2 class="font-weight-light text-uppercase">{{ title }}</h2>
    <div class="underline" v-bind:style="{ backgroundColor: textColor }"></div>
  </div>
</template>
<script>
// #7B61FF
export default {
  name: 'SectionTitle',
  props: {
    title: {
      type: String,
      default: "Título",
      description: 'Title of the section'
    },
    textColor: {
      type: String,
      default: '#74B3AB',
      description: 'Color of title'
    },
  },
  data() {
    return {}
  },

}
</script>
<style lang="scss">
.section-title-container {
  width: fit-content;
  margin-bottom: 30px;

  h2 {
    margin: 0 !important;
  }

  .underline {
    height: 10px;
  }
}
</style>
