<template>
  <section class="md-blue-color-bg section">
    <div class="container-fluid">
      <div class="justify-content-around align-items-center px-5 row">
        <div class="ml-md-5 col">
          <p class="medium-large-text card-description light-blue-text semibold-text"
            style="max-width: 450px; word-wrap: break-word">
            Si quieres conocer más datos y detalles sobre la transparencia de los sistemas
            de justicia de América Latina, no dejes de escribirnos a:
            <a href="mailto:correo@alianzaregional.net" class="small-text">correo@alianzaregional.net</a>
          </p>
        </div>
        <div class="col">
          <report-buttons v-bind:light-background="true"></report-buttons>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ReportButtons } from "@/components";

export default {
  name: "KnowMoreSection",
  components: {
    ReportButtons,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss"></style>
