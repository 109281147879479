<template>
  <section class="light-blue-bg section">
    <div class="container-fluid">
      <div class="justify-content-center mb-5 row">
        <div class="col-md-12">
          <h3
            class="mx-4 text-center text-uppercase bold-text dark-rose-text large-text title"
          >
            Otros informes de la Alianza Regional
          </h3>
        </div>
      </div>
      <div class="justify-content-center m-5 row">
        <div
          class="col-md-4"
          style="cursor: pointer"
          onclick="window.open('https://www.derechoasaber.com/', '_blank')"
        >
          <card type="blog" plain>
            <img slot="image" class="img" src="img/resources/banner-derecho-saber.jpg" />
            <h4 class="text-center text-uppercase card-title">
              <span
                class="medium-text text-center text-uppercase bold-text dark-blue-text dark-rose-text title"
              >
                TENEMOS DERECHO A SABER
              </span>
            </h4>
            <p
              class="medium-text text-center text-justify card-description dark-blue-text semibold-text"
            >
              Una campaña por el derecho de acceso a la información pública en Cuba.
            </p>
          </card>
        </div>
        <div
          class="col-md-4"
          style="cursor: pointer"
          onclick="window.open('https://www.alianzaregional.net/el-impacto-en-la-libertad-de-expresion-de-la-violencia-de-genero-en-linea-hacia-mujeres-con-voz-publica/', '_blank')"
        >
          <card type="blog" plain>
            <img slot="image" class="img" src="img/resources/impacto_libertad.jpg" />
            <h4 class="text-center text-uppercase card-title">
              <span
                class="medium-text text-center text-uppercase bold-text dark-blue-text dark-rose-text title"
                >Violencia de género en línea</span
              >
            </h4>
            <p
              class="medium-text text-center card-description dark-blue-text semibold-text"
            >
              El impacto en la libertad de expresión de mujeres con voz pública de América
              Latina y el Caribe
            </p>
          </card>
        </div>
        <div
          class="col-md-4"
          style="cursor: pointer"
          onclick="window.open('https://www.alianzaregional.net/articulo-xiii-libertad-de-expresion-en-linea-desafios-oportunidades-y-tendencias-en-america-latina-2/', '_blank')"
        >
          <card type="blog" plain>
            <img slot="image" class="img" src="img/resources/libertad-expresion.png" />
            <h4 class="text-center text-uppercase card-title">
              <span
                class="medium-text text-center text-uppercase bold-text dark-blue-text dark-rose-text title"
                >Libertad de expresión en línea</span
              >
            </h4>
            <p
              class="medium-text text-center card-description dark-blue-text semibold-text"
            >
              Desafíos, oportunidades y tendencias en América Latina.
            </p>
          </card>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { Card } from "@/components";

export default {
  name: "OtherReportsSection",
  components: {
    Card,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss"></style>
