<template>
  <section class="section section-second-report" style="padding-top: 0px !important">
    <div class="w-100" style="
        background-image: url('img/resources/back-banner-2.png');
        background-size: cover;
      ">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-1" />

          <div class="px-5 align-self-md-center align-self-start col-md-4 container texts-container">
            <section-title title="Informe II" text-color="#C9FBF4" />
            <p class="large-text semibold-text main-title" style="color: #c9fbf4">
              Opacidad en los sistemas judiciales de la región II: selección, evaluación y remoción de autoridades
            </p>
            <p class="text-white large-text bold-text subtitle" style="margin-top: 70px">
              ¿Está prevista la participación ciudadana en los procesos de selección?
            </p>
          </div>
          <div class="col-md-7">
            <img class="d-block d-md-none" src="img/resources/mapa-2-mobile.png" />
            <img class="d-none d-md-block" src="img/resources/mapa-2.png" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { SectionTitle } from "@/components";

export default {
  name: "SecondReportSection",
  components: {
    SectionTitle,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.texts-container {
  margin-top: 0;

  .main-title {
    line-height: 1.2em;
  }
}

@media (max-width: 768px) {
  .texts-container {
    margin-top: 200px;

    .subtitle {
      font-size: 1.5em;
      line-height: 1.2em;
    }
  }
}
</style>
