<template>
  <section class="section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-1" />
        <div class="col-lg-10">
          <ul class="d-lg-flex d-none row">
            <li v-for="section in sections" :key="section.pos" class="col-12 col-md">
              <section-card :item="section" />
            </li>
          </ul>
          <div class="d-block d-lg-none row">
            <div class="col-12">
              <carousel :perPage="1" paginationActiveColor="#BD889B" paginationColor="#A7A7B3">
                <slide v-for="section in sections" :key="section.pos">
                  <section-card :item="section" />
                </slide>
              </carousel>
            </div>
          </div>
        </div>
        <div class="col-lg-1"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { SectionCard } from "@/components";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "SectionTypesSection",
  components: {
    SectionCard,
    Carousel,
    Slide,
  },
  data() {
    return {
      sections: [
        {
          pos: 1,
          title: "Ingreso y selección",
          description:
            "La transparencia en esta etapa inicial es fundamental para la incorporación de perfiles idóneos. Resulta clave contar con procesos que garanticen la publicidad de las convocatorias, el acceso completo a los antecedentes, conocimientos evaluados y entrevistas, riesgos de conflictos de intereses y oportunidades de participación ciudadana, entre otros.",
        },
        {
          pos: 2,
          title: "Seguimiento y control del desempeño",
          description:
            "Para garantizar la transparencia y la calidad del sistema judicial es preciso conocer la eficacia, eficiencia y calidad del trabajo que se realiza. Publicitar los procedimientos y responsables, del seguimiento y control del desempeño, e informar los resultados de sus evaluaciones, fomenta la rendición de cuentas y permite identificar oportunidades de formación y mejoras.",
        },
        {
          pos: 3,
          title: "Sanción y remoción",
          description:
            "Para velar por la integridad del sistema judicial es esencial la transparencia en estas instancias. La publicidad de los procesos solicitados, abiertos y resueltos, así como el sentido de las resoluciones tomadas, fortalece el control social y la rendición de cuentas de la administración de justicia.",
        },
      ],
    };
  },
};
</script>
<style lang="scss">
ul {
  padding-inline-start: 0;

  li {
    list-style: none;
  }
}
</style>
