<template>
  <div class="px-0 w-100">

    <banner-section />

    <explain-section />

    <first-report-section />

    <second-report-section />

    <section-types-section />

    <second-report-categories-section />

    <other-reports-section />

    <know-more-section />

    <members-section />

    <footer-section />
  </div>
</template>

<script>
import {
  BannerSection, ExplainSection, FirstReportSection, SecondReportSection, SectionTypesSection,
  SecondReportCategoriesSection, OtherReportsSection, KnowMoreSection, MembersSection, FooterSection
} from "@/components";

export default {
  name: "Landing.vue",
  components: {
    BannerSection,
    ExplainSection,
    FirstReportSection,
    SecondReportSection,
    SectionTypesSection,
    SecondReportCategoriesSection,
    OtherReportsSection,
    KnowMoreSection,
    MembersSection,
    FooterSection,
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">
.gray-bg {
  background-color: var(--bg-gray-color);
}

.dark-rose-text {
  color: var(--dark-rose-color);
}

.dark-rose-bg {
  background-color: var(--dark-rose-color) !important;
}

.md-blue-color-bg {
  background-color: var(--md-blue-color) !important;
}

.dark-blue-text {
  color: var(--dark-blue-color) !important;
}

.dark-blue-bg {
  background-color: var(--dark-blue-color) !important;
}

.light-blue-text {
  color: var(--light-blue-color) !important;
}

.light-blue-bg {
  background-color: var(--light-blue-color) !important;
}

.green-text {
  color: var(--green-color);
}

.green-bg {
  background-color: var(--green-color) !important;
}

.medium-green-text {
  color: var(--medium-green-color);
}

.dark-green-text {
  color: var(--dark-green-color);
}

.extra-small-text {
  font-size: .6em;
  line-height: .6em;
}

.small-text {
  font-size: .8em;
  line-height: .8em;
}

.medium-quote {
  font-size: 2em;
  line-height: 1.2em;
}

.medium-text {
  font-size: 1.2em;
  line-height: 1.2em;
}

.medium-large-text {
  font-size: 1.5em;
  line-height: 1.3em;
}

.large-text {
  font-size: 2em;
  line-height: 1.5em;
}

.regular-text {
  font-family: Montserrat-Regular;
}

.semibold-text {
  font-family: Montserrat-SemiBold;
}

.bold-text {
  font-family: Montserrat-Bold;
}

.super-bold-text {
  font-family: Montserrat-Black;
}

.monts-italic {
  font-family: Montserrat-MediumItalic;
}

.gray-text {
  color: #7d8281;
}

.section {
  padding: 30px 0 !important;
}
</style>
