<template>
    <div class="card-section">
        <div class="card-section-header" :style="getBackgroundImageStyle(item)">
            <div class="w-100 px-5">
                <span class="w-50 bold-text medium-large-text">{{ item.title }}</span>
            </div>
        </div>
        <div class="p-4">
            <p class="regular-text">{{ item.description }}</p>
        </div>
    </div>
</template>
<script>
import Card from './Card.vue'

export default {
    name: 'SectionCard',
    components: {
        Card
    },
    props: {
        item: {
            title: String,
            description: String
        }
    },
    methods: {
        getVideoPath(section) {
            // Return the path to the video or image based on the section
            return `img/resources/sections/section_${section.pos}.png`;
        },
        getBackgroundImageStyle(section) {
            return {
                backgroundImage: `url(${this.getVideoPath(section)})`
            };
        }
    }
}

</script>
<style>
.card-section {
    background-color: #E7E7F7;
    height: 100%;

    .card-section-header {
        display: flex;
        align-items: center;
        justify-items: center;
        width: 100%;
        height: 200px;
        background-size: cover;
        color: white;
        text-align: center;
    }

    p {
        color: black;
    }
}
</style>