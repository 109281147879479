<template>
    <main id="app main">
        <router-view name="header" />
        <router-view />
        <router-view name="footer" />
    </main>
</template>
<script>
export default {
    mounted() {
        
    }
};
</script>
<style>
:root {
    --green-color: #5eb5ab;
    --medium-green-color: #5eb5ab;
    --dark-green-color: #08645f;
    --rose-color: #c7346c;
    --dark-rose-color: #951d43;
    --yellow-color: #f59b11;
    --dark-blue-color: #2c2c70;
    --light-blue-color: #def4f3;
    --md-blue-color: #5858d5;
    --bg-gray-color: #bdbcbc;
    --blue-divider-color: #5353b9;
}

/* html {
  overflow-x: hidden;
} */


@font-face {
    font-family: "Montserrat-Black";
    src: url("assets/fonts/montserrat/Montserrat-Black.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: "Montserrat-Italic";
    src: url("assets/fonts/montserrat/Montserrat-Italic.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: "Montserrat-MediumItalic";
    src: url("assets/fonts/montserrat/Montserrat-MediumItalic.otf")
        format("opentype");
    font-display: swap;
}

@font-face {
    font-family: "Montserrat-Regular";
    src: url("assets/fonts/montserrat/Montserrat-Regular.otf")
        format("opentype");
    font-display: swap;
}

@font-face {
    font-family: "Montserrat-SemiBold";
    src: url("assets/fonts/montserrat/Montserrat-SemiBold.otf")
        format("opentype");
    font-display: swap;
}

@font-face {
    font-family: "Montserrat-Bold";
    src: url("assets/fonts/montserrat/Montserrat-Bold.otf") format("opentype");
    font-display: swap;
}
</style>
