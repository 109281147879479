<template>
  <footer class="py-4 dark-rose-bg section section-footer">
    <div class="container">
      <div class="justify-content-center align-items-center row">
        <div class="order-3 order-lg-1 mt-2 mt-lg-0 text-center col-md-2">
          <social-icon href="https://twitter.com/AlianzaRegional" icon="twitter"></social-icon>
          <social-icon href="https://www.facebook.com/AlianzaRegional/" icon="facebook"></social-icon>
          <social-icon href="https://www.youtube.com/channel/UCYxJ9J3dm46Jhd-eD-kvopw" icon="youtube"></social-icon>
        </div>
        <div class="my-2 my-lg-0 text-center col-md-2">
          <img slot="image" width="100" class="img" src="img/resources/logo-alianza.png" />
        </div>
        <div class="mt-2 mt-lg-0 text-center text-white bold-text col-md-2">
          <a href="https://www.alianzaregional.net/" target="_blank" class="text-white">www.alianzaregional.net</a>
        </div>
      </div>
      <div class="justify-content-center align-items-center mt-3 row">
        <small class="text-white bold-text small-text">Desarrollado por Mediaplus Experience</small>
      </div>
    </div>
  </footer>
</template>
<script>
import { SocialIcon } from "@/components";

export default {
  name: 'FooterSection',
  components: {
    SocialIcon
  },
  data() {
    return {}
  },

}
</script>
<style lang="scss"></style>
