<template>
  <div class="card card-plain">
    <div id="headingOne" role="tab" class="card-header py-4 align-items-center">
      <a data-toggle="collapse" data-parent="#accordion" :href="`#${itemId}`" :aria-expanded="active"
        :aria-controls="`content-${itemId}`" @click.prevent="activate">
        <slot name="title" v-bind:active="active">
          {{ title }}
        </slot>
        <slot name="icon">

        </slot>
      </a>
    </div>
    <collapse-transition :duration="animationDuration">
      <div v-show="active" :id="`content-${itemId}`" role="tabpanel" :aria-labelledby="title" class="collapsed">
        <div class="card-body">
          <slot />
        </div>
      </div>
    </collapse-transition>
  </div>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';

export default {
  name: 'CollapseItem',
  components: {
    CollapseTransition
  },
  inject: {
    animationDuration: {
      default: 250
    },
    multipleActive: {
      default: false
    },
    addItem: {
      default: () => { }
    },
    removeItem: {
      default: () => { }
    },
    deactivateAll: {
      default: () => { }
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    id: String,
    noIcon: Boolean
  },
  data() {
    return {
      active: false
    }
  },
  computed: {
    itemId() {
      return this.id || this.title
    }
  },
  mounted() {
    this.addItem(this)
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    this.removeItem(this)
  },
  methods: {
    activate() {
      if (!this.multipleActive) {
        this.deactivateAll();
      }
      this.active = !this.active;
    }
  }
}
</script>
<style lang="scss" scoped>
.card-collapse {

  .card {
    margin-bottom: 0 !important;

    &:first-child:before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 2px;
      background-color: var(--blue-divider-color);
    }

    &:last-child:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: var(--blue-divider-color);
    }

    .card-header:before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 1px;
      background-color: var(--blue-divider-color);
    }

    .card-header:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: transparent !important;
    }

    .card-body {
      padding: 0 !important;
    }
  }
}
</style>
