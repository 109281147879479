var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section section-second-categories-report"},[_c('div',{ref:"sticky-categories",staticClass:"sticky-top py-3"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-1"}),_vm._m(0),_c('div',{staticClass:"col-md-6"},[_c('ul',{staticClass:"category-buttons mb-0"},_vm._l((_vm.categories),function(category){return _c('li',{key:category.id,class:[
              ("category-" + (category.id)) === _vm.categoryActive ? 'active' : '',
              ("btn-category-" + (category.id)),
              'bold-text px-1 py-2 px-md-3 py-md-3 text-uppercase' ],on:{"click":function($event){return _vm.scrollToCategory(category.id)}}},[_c('span',[_vm._v(_vm._s(category.name))])])}),0)]),_c('div',{staticClass:"col-md-1"})])])]),_vm._l((_vm.categories),function(category,categoryIndex){return _c('div',{key:category.id,ref:"category",refInFor:true,staticClass:"container-fluid",attrs:{"id":("category-" + (category.id))}},_vm._l((category.sections),function(section,sectionIndex){return _c('div',{key:section.name,class:[_vm.getRowClass(categoryIndex, sectionIndex), 'row my-5']},[_c('div',{staticClass:"col-md-1"}),_c('div',{staticClass:"col-12 col-md"},[_c('p',{staticClass:"px-3 text-uppercase bold-text dark-rose-text"},[_vm._v(_vm._s(category.name))]),_c('h3',{staticClass:"px-3 text-uppercase bold-text dark-rose-text"},[_vm._v(_vm._s(section.name))]),_c('collapse',{attrs:{"active-index":section.questionIndexSelected,"multipleActive":false}},_vm._l((section.questions),function(question,questionIndex){return _c('collapse-item',{key:question.id,scopedSlots:_vm._u([{key:"title",fn:function(ref){
            var active = ref.active;
return [_c('div',{staticClass:"d-flex align-items-center px-3 collapse-title",on:{"click":function($event){return _vm.selectQuestion(section, questionIndex)}}},[_c('span',{class:[
                  'flex-grow-1',
                  'regular-text',
                  'medium-large-text',
                  { 'text-black': !active, 'dark-rose-text': active } ]},[_vm._v(" "+_vm._s(question.title)+" ")]),_c('i',{staticClass:"fa fa-caret-down",staticStyle:{"font-size":"2em","color":"var(--dark-rose-color)"}})])]}}],null,true)},[_c('div',{staticClass:"show-on-mobile mb-4"},[_c('video-custom',{attrs:{"slot":"video","video-src":_vm.getVideoPath(category.id, section)},slot:"video"})],1)])}),1)],1),_c('div',{staticClass:"d-md-block col-md d-none"},[_c('video-custom',{attrs:{"video-src":_vm.getVideoPath(category.id, section)}})],1),_c('div',{staticClass:"col-md-1"})])}),0)})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-4"},[_c('p',{staticClass:"px-4 px-md-0 bold-text mb-0",staticStyle:{"color":"#2f2f6b"}},[_vm._v(" ¿Qué información se publica de estos procesos en los diferentes ámbitos del sistema judicial? ")])])}]

export { render, staticRenderFns }