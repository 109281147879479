<template>
  <section class="section section-members">
    <div class="container">
      <div class="justify-content-center mb-lg-5 row">
        <div class="col-md-5">
          <h3
            class="mx-4 text-center text-uppercase bold-text dark-blue-text large-text title"
          >
            Organizaciones miembro de la Alianza Regional
          </h3>
        </div>
      </div>
      <div class="flex-wrap justify-content-center mb-2 px-lg-5 row">
        <div class="mb-3 col-6 col-md-2">
          <img
            slot="image"
            class="img"
            src="img/resources/members/accion-ciudadadana.png"
          />
        </div>
        <div class="mb-3 col-6 col-md-2">
          <img slot="image" class="img" src="img/resources/members/artigo 19.png" />
        </div>
        <div class="mb-3 col-6 col-md-2">
          <img slot="image" class="img" src="img/resources/members/espacio-publico.png" />
        </div>
        <div class="mb-3 col-6 col-md-2">
          <img slot="image" class="img" src="img/resources/members/Fusades.png" />
        </div>
        <div class="mb-3 col-6 col-md-2" style="text-align: center">
          <img slot="image" class="img" src="img/resources/members/IDEA.png" />
        </div>
        <div class="mb-3 col-6 col-md-2">
          <img slot="image" class="img" src="img/resources/members/Iplex.jpg" />
        </div>
        <div class="mb-3 col-6 col-md-2">
          <img
            slot="image"
            class="img"
            src="img/resources/members/FCD 2020 variantes.png"
          />
        </div>
        <div class="mb-3 col-6 col-md-2">
          <img slot="image" class="img" src="img/resources/members/Logo Colectivo.png" />
        </div>
        <div class="mb-3 col-6 col-md-2">
          <img
            slot="image"
            class="img"
            src="img/resources/members/LOGO-ACIJ-COLOR-FONDO-TRANSPARENTE(2).png"
          />
        </div>
        <div class="mb-3 col-6 col-md-2">
          <img slot="image" class="img" src="img/resources/members/CaInfo.png" />
        </div>
        <div class="mb-3 col-6 col-md-2">
          <img
            slot="image"
            class="img"
            src="img/resources/members/transparencia venezuela.png"
          />
        </div>
        <div class="mb-3 col-6 col-md-2">
          <img
            slot="image"
            class="img"
            src="img/resources/members/tranparencia-Colombia.png"
          />
        </div>
        <div class="mb-3 col-6 col-md-2">
          <img slot="image" class="img" src="img/resources/members/Fundar.png" />
        </div>
        <div class="mb-3 col-6 col-md-2">
          <img
            slot="image"
            class="img"
            src="img/resources/members/Logo FLED sin fondo.png"
          />
        </div>
        <div class="mb-3 col-6 col-md-2">
          <img slot="image" class="img" src="img/resources/members/OCDH.png" />
        </div>
        <div class="mb-3 col-6 col-md-2">
          <img slot="image" class="img" src="img/resources/members/IPANDETEC.png" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "MembersSection",
  data() {
    return {};
  },
};
</script>
<style lang="scss">
@media (max-width: 768px) {
  .section-members {
    padding: 1rem !important;

    .img {
      height: 100% !important;
      min-width: 20vw !important;
      padding: 1rem;
      object-fit: contain;
    }
  }
}
</style>
