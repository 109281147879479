<template>
  <section class="pb-0 light-blue-bg" style="margin-top: -5px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 col-lg-6 col-md-12 imgs-container" style="min-height: 850px">
          <div>
            <img v-animate-onscroll="{ down: 'animated slideInRight' }" slot="image"
              class="d-lg-block d-none img img-hands" src="img/resources/hands-with-phone.png" />
            <img v-animate-onscroll="{ down: 'animated slideInLeft' }" slot="image" class="img img-justicia" style=""
              src="img/resources/justicia-ciega.png" />
            <img v-animate-onscroll="{ down: 'animated slideInUp' }" slot="image"
              class="d-lg-block d-none img img-megafono" style="" src="img/resources/megafono.png" />
          </div>
        </div>
        <div class="col-sm-12 col-lg-6 col-md-12">
          <div class="text-container semibold-text">
            <p class="dark-blue-text">
              ¿Por qué es importante el acceso a la información del sistema judicial? Porque la justicia tiene un doble
              rol: es la instancia local final de garantía y reclamo para la debida aplicación de las normas nacionales
              y acuerdos regionales; al tiempo que es un sujeto obligado a cumplir esas mismas normativas.
            </p>
            <p class="dark-blue-text">
              Nuestros estudios verifican que <span class="dark-rose-text bold-text">la región presenta altos niveles de
                opacidad en la
                gestión y
                desempeño del
                sistema de justicia, sus decisores/as y operadores/as</span>. Esta falta de información es el resultado
              de una
              cultura histórica del secretismo judicial y refuerza el alto grado de desconfianza ciudadana, que necesita
              conocer cómo funciona este Poder responsable de resolver disputas y garantizar derechos.
            </p>
            <report-buttons v-bind:showIcons="false" btn-prefix="Ver"></report-buttons>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ReportButtons } from "@/components";

export default {
  name: "ExplainSection",
  components: {
    ReportButtons,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.img-hands {
  width: 60%;
  bottom: 0px;
  position: absolute;
  right: -5%;
}

.img-justicia {
  width: 380px;
  top: 5%;
  position: absolute;
  left: 40%;
}

.img-megafono {
  height: 700px;
  bottom: 0px;
  position: absolute;
  left: 0;
}

.text-container {
  margin: 100px 15% auto 2%;
}

@media (max-width: 768px) {
  .text-container {
    margin: 12%;
  }

  .img-justicia {
    width: 250px;
    top: -15%;
    position: absolute;
    left: 50%;
    margin-left: -125px;
  }

  .img-hands-movil {
    width: 100%;
    bottom: 0px;
    position: absolute;
    right: 0%;
  }

  .img-megafono-movil {
    height: 100%;
    bottom: 0px;
    position: absolute;
    left: 0;
  }

  .imgs-container {
    min-height: 200px !important;
  }
}
</style>
