<template>
  <div class="buttons-container my-5">
    <div class="d-flex flex-column flex-md-row flex-wrap">
      <a href="https://us18.list-manage.com/contact-form?u=0acb45e8c065e160ba2ba393d&form_id=f13a9a9945cf22d98542752aac21b0f4"
        target="_blank"
        v-bind:class="['btn btn-report green-bg rounded-0 d-flex align-items-center', lightBackground ? 'hover-light' : 'hover-purple']">
        <p class="bold-text text-uppercase text-left p-0 m-0">{{ btnPrefix }} Informe I</p>
        <i v-if="showIcons" class="now-ui-icons arrows-1_cloud-download-93 icon-btn" />
      </a>
      <div class="separator"></div>

      <div class="position-relative">
        <div
          v-bind:class="['bubble-new ', lightBackground ? 'bubble-light' : 'bubble-purple', 'd-flex', 'align-items-center', 'justify-content-center']">
          <span class="bold-text" style="font-size: 12px;">NEW</span>
        </div>
        <a href="https://us18.list-manage.com/contact-form?u=0acb45e8c065e160ba2ba393d&form_id=f13a9a9945cf22d98542752aac21b0f4"
          target="_blank"
          v-bind:class="['btn btn-report dark-rose-bg rounded-0 d-flex align-items-center', lightBackground ? 'hover-light' : 'hover-purple']">
          <p class="bold-text text-uppercase text-left p-0 m-0">{{ btnPrefix }} Informe II</p>
          <i v-if="showIcons" class="now-ui-icons arrows-1_cloud-download-93 icon-btn" />
        </a>
      </div>

    </div>
  </div>
</template>
<script>
// #7B61FF
export default {
  name: 'ReportButtons',
  props: {
    showIcons: {
      type: Boolean,
      default: true,
      description: 'Show or hide the icon in the buttons'
    },
    btnPrefix: {
      type: String,
      default: "Descargar",
      description: 'Change button prefix'
    },
    lightBackground: {
      type: Boolean,
      default: false,
      description: 'Change the bubble background'
    }
  },
  data() {
    return {}
  },

}
</script>
<style lang="scss">
.btn-report {
  gap: 1rem;
  margin: 0 !important;
  padding: 20px 15px !important;
  font-size: 1.5em;
  line-height: 1.2em;

  &.hover-purple:hover {
    background-color: #7B61FF !important;
  }

  &.hover-light:hover {
    background-color: #E2F4F3 !important;
    color: #050505;
  }

  i {
    font-size: 3em;
  }
}

.separator {
  width: 2px;
  background-color: #D9D9D9;
}

.bubble-new {
  width: 50px;
  height: 50px;
  border-radius: 50% 50% 50% 0;
  position: absolute;
  right: -35px;
  top: -35px;
  background-color: #7B61FF;

  &.bubble-purple {
    background-color: #7B61FF;
    color: #FEFBFB;
  }

  &.bubble-light {
    background-color: #DFFEFD;
    color: #050505;
  }
}

@media (max-width: 768px) {
  .bubble-new {
    width: 40px;
    height: 40px;
    top: 0px;
    right: -30px;
  }

  .btn-report {
    padding: 10px 8px;
    font-size: 1.2em;
    line-height: 1em;

    i {
      font-size: 2em;
    }
  }
}
</style>
