<template>
  <section class="section section-first-report">
    <div class="container-fluid">
      <div class="justify-content-end align-items-center row title">
        <div class="col-md-1" />

        <div class="col-md-4">
          <section-title title="Informe I" text-color="#74B3AB" />
          <p class="gray-text large-text regular-text">
            En <span class="medium-green-text super-bold-text">"Saber+: <br /></span>
            <span class="medium-green-text bold-text">Opacidad en los sistemas judiciales de la región"
            </span>
            repasamos el estado de la transparencia activa en relación a varios aspectos:
          </p>
        </div>
        <div class="col-md-7">
          <p class="dark-rose-text regular-text" style="max-width: 810px">
            La región presenta niveles de opacidad extremos en relación a la gestión y
            desempeño del sistema de justicia, sus decisores/as y operadores/as. Esta
            falta de información sostiene la cultura histórica del secretismo judicial y
            refuerza el alto grado de desconfianza ciudadana.
          </p>
        </div>
      </div>
      <div class="justify-content-end row">
        <div class="col-md-1" />

        <div class="col-md-4">
          <collapse :active-index="indexImage" :multipleActive="false">
            <collapse-item>
              <template v-slot:title="{ active }">
                <div class="d-flex collapse-title">
                  <span class="flex-grow-1" v-on:click="indexImage = 0" style="display: block" :class="[
                    'regular-text',
                    'medium-large-text',
                    { 'text-black': !active, 'dark-rose-text': active },
                  ]">¿Cómo se organizan y de qué modo contactarlas?</span>
                  <i v-on:click="indexImage = 0" class="fa fa-caret-down"
                    style="font-size: 2em; color: var(--dark-rose-color)" />
                </div>
              </template>
              <div class="medium-text text-black regular-text">
                <p class="collapse-text">
                  Conocer cómo se organizan las agencias y unidades del sistema, quiénes
                  toman las decisiones, de qué asuntos se ocupan, o cuáles son las vías
                  para contactarles, resulta elemental para que la ciudadanía pueda hacer
                  uso del servicio de justicia.
                </p>
                <img class="show-on-mobile mb-4" slot="image" :src="images[0].src" />
              </div>
            </collapse-item>

            <collapse-item>
              <template v-slot:title="{ active }">
                <div class="d-flex collapse-title">
                  <span class="flex-grow-1" v-on:click="indexImage = 1" style="display: block" :class="[
                    'regular-text',
                    'medium-large-text',
                    { 'text-black': !active, 'dark-rose-text': active },
                  ]">¿Con qué fondos cuentan y en qué los invierten?
                  </span>
                  <i v-on:click="indexImage = 1" class="fa fa-caret-down"
                    style="font-size: 2em; color: var(--dark-rose-color)" />
                </div>
              </template>
              <div class="medium-text text-black regular-text">
                <p class="collapse-text">
                  La información presupuestaria permite conocer la inversión pública en el
                  sistema de justicia y el modo en que esta se distribuye. Sin embargo, el
                  nivel de opacidad en este campo es muy elevado.
                </p>
                <img class="show-on-mobile mb-4" slot="image" :src="images[1].src" />
              </div>
            </collapse-item>

            <collapse-item>
              <template v-slot:title="{ active }">
                <div class="d-flex collapse-title">
                  <span class="flex-grow-1" v-on:click="indexImage = 2" style="display: block" :class="[
                    'regular-text',
                    'medium-large-text',
                    { 'text-black': !active, 'dark-rose-text': active },
                  ]">¿Qué y cómo gestionan?</span>
                  <i v-on:click="indexImage = 2" class="fa fa-caret-down"
                    style="font-size: 2em; color: var(--dark-rose-color)" />
                </div>
              </template>
              <div class="medium-text text-black regular-text">
                <p class="collapse-text">
                  La información relativa a la gestión y el seguimiento de expedientes
                  permite construir una idea sobre la eficiencia y pertinencia del trabajo
                  que realiza la justicia. Asimismo, conocer los asuntos que se tramitan
                  permite comprender la conflictividad social y procesos como la
                  judicialización de la política. Sin embargo, en la región son muy
                  limitadas las oportunidades para acceder a ese tipo de información.
                </p>
                <img class="show-on-mobile mb-4" slot="image" :src="images[2].src" />
              </div>
            </collapse-item>

            <collapse-item>
              <template v-slot:title="{ active }">
                <div class="d-flex collapse-title">
                  <span class="flex-grow-1" v-on:click="indexImage = 3" style="display: block" :class="[
                    'regular-text',
                    'medium-large-text',
                    { 'text-black': !active, 'dark-rose-text': active },
                  ]">¿Qué asuntos resuelven y cómo lo hacen?
                  </span>
                  <i v-on:click="indexImage = 3" class="fa fa-caret-down"
                    style="font-size: 2em; color: var(--dark-rose-color)" />
                </div>
              </template>
              <div class="medium-text text-black regular-text">
                <p class="collapse-text">
                  Difundir sus decisiones e informar el modo en que contribuye a la
                  gestión de los conflictos, debe ser una prioridad para cualquier sistema
                  de justicia, especialmente en aquellos asuntos de evidente interés
                  público. No obstante, esto no se verifica en la región.
                </p>
                <img class="show-on-mobile mb-4" slot="image" :src="images[3].src" />
              </div>
            </collapse-item>

            <collapse-item>
              <template v-slot:title="{ active }">
                <div class="d-flex collapse-title">
                  <span class="flex-grow-1" v-on:click="indexImage = 4" style="display: block" :class="[
                    'regular-text',
                    'medium-large-text',
                    { 'text-black': !active, 'dark-rose-text': active },
                  ]">¿Quién selecciona a jueces y juezas de las cortes superiores?
                  </span>
                  <i v-on:click="indexImage = 4" class="fa fa-caret-down"
                    style="font-size: 2em; color: var(--dark-rose-color)" />
                </div>
              </template>
              <div class="medium-text text-black regular-text">
                <p class="collapse-text">
                  Conocer quién y con qué criterios seleccionan a las personas que
                  integran los máximos tribunales nacionales / federales, es fundamental
                  para construir ideas informadas, por ejemplo, sobre las posibilidades y
                  límites de su independencia. Pero este tema también es muy ambiguo en la
                  región.
                </p>
                <img class="show-on-mobile mb-4" slot="image" :src="images[4].src" />
              </div>
            </collapse-item>

            <collapse-item>
              <template v-slot:title="{ active }">
                <div class="d-flex collapse-title">
                  <span class="flex-grow-1" v-on:click="indexImage = 5" style="display: block" :class="[
                    'regular-text',
                    'medium-large-text',
                    { 'text-black': !active, 'dark-rose-text': active },
                  ]">¿Cómo es el desempeño de quienes toman decisiones en los sistemas de
                    justicia?</span>
                  <i v-on:click="indexImage = 5" class="fa fa-caret-down"
                    style="font-size: 2em; color: var(--dark-rose-color)" />
                </div>
              </template>
              <div class="medium-text text-black regular-text">
                <p class="collapse-text">
                  Analizar la performance de las personas que toman decisiones dentro del
                  sistema judicial, permite al Estado y a la ciudadanía conocer su calidad
                  profesional e impulsar políticas de fortalecimiento y mejora para
                  alcanzar metas institucionales. Sin embargo, en la región son
                  extremadamente limitadas las prácticas de evaluación del conocimiento
                  y/o desempeño de estas personas.
                </p>
                <img class="show-on-mobile mb-4" slot="image" :src="images[5].src" />
              </div>
            </collapse-item>

            <collapse-item>
              <template v-slot:title="{ active }">
                <div class="d-flex collapse-title">
                  <span class="flex-grow-1" v-on:click="indexImage = 6" style="display: block" :class="[
                    'regular-text',
                    'medium-large-text',
                    { 'text-black': !active, 'dark-rose-text': active },
                  ]">¿Quiénes y cómo remueven a las personas decisoras?</span>
                  <i v-on:click="indexImage = 6" class="fa fa-caret-down"
                    style="font-size: 2em; color: var(--dark-rose-color)" />
                </div>
              </template>
              <div class="medium-text text-black regular-text">
                <p class="collapse-text">
                  Si una persona quisiera impulsar o conocer el estado de trámite de un
                  proceso para remover magistrados, deberá recibir orientación de algún o
                  alguna experta debido a que la información pública es extremadamente
                  limitada.
                </p>
                <img class="show-on-mobile mb-4" slot="image" :src="images[6].src" />
              </div>
            </collapse-item>
          </collapse>
        </div>

        <div class="d-md-block mt-4 mt-lg-0 col-md-7 d-none" style="padding: 0px">
          <div v-sticky="{ zIndex: 10, stickyTop: 10, disabled: false }">
            <img slot="image" style="max-height: 100vh; margin: 0px 10px" :alt="images[indexImage].title"
              :title="images[indexImage].title" :src="images[indexImage].src" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { Collapse, CollapseItem, SectionTitle } from "@/components";
import VueSticky from "vue-sticky";

export default {
  name: "FirstReportSection",
  directives: {
    sticky: VueSticky,
  },
  components: {
    Collapse,
    CollapseItem,
    SectionTitle,
  },
  data() {
    return {
      indexImage: 0,
      images: [
        {
          src: "img/resources/first-report/1-pregunta.gif",
          alt: "Datos que no se publican en algunos países de la región",
        },

        {
          src: "img/resources/first-report/2-pregunta.gif",
          alt: "Datos que no se publican en algunos países de la región",
        },

        {
          src: "img/resources/first-report/3-pregunta.gif",
          alt:
            "Países que carecen de sistema electrónico de gestión de expedientes y seguimiento de causas, accesible para la ciudadanía",
        },

        {
          src: "img/resources/first-report/4-pregunta.gif",
          alt: "Datos que no se publican en algunos países de la región",
        },

        {
          src: "img/resources/first-report/5-pregunta.gif",
          alt: "Datos que no se publican en algunos países de la región",
        },

        {
          src: "img/resources/first-report/6-pregunta.gif",
          alt: "Roles que no se evalúan anualmente",
        },

        {
          src: "img/resources/first-report/7-pregunta.gif",
          alt: "Datos que no se publican en algunos países de la región",
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.section-first-report .show-on-mobile {
  display: none;
}

@media (max-width: 768px) {
  .section-first-report {
    padding: 0 !important;
    margin: 0 !important;

    .title {
      padding: 1.5rem;
    }

    .show-on-mobile {
      display: block;
    }

    .collapse-title,
    .collapse-text {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }
}
</style>
