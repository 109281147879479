<template>
  <section class="banner-height mobile-banner-height"
    style="width: 100%; min-height: 1150px; background-image: url('img/resources/back-banner.png'); background-size: cover">
    <div class="row inside-image-box inner-banner-height w-100" style="min-height: 950px">
      <div class="col-sm-12 col-md-12 col-lg-6">
        <div style="margin: auto 100px" class="content-banner">
          <div class="row mt-5 logo-alianza">
            <img slot="image" class="img" style="width: 150px" src="img/resources/logo-alianza.png" />
          </div>
          <div class="row">
            <p class="green-text text-uppercase banner-title-text">Saber +</p>
            <p class="dark-rose-text text-uppercase banner-subtitle-text pb-0">Opacidad en los Sistemas de
              Justicia de la región</p>

            <p class="semibold-text text-white medium-text">
              El <span class="green-text bold-text">acceso a la información pública</span> (AIP) es un derecho humano
              fundamental:
              nos permite acceder a otros derechos y garantías, conocer las decisiones estatales, el destino de los
              fondos públicos, la calidad del trabajo que se realiza, entre otros. Por eso, las normas de AIP establecen
              criterios de <span class="green-text bold-text">Transparencia Activa</span> (TA) que obligan al Estado a
              publicar y
              actualizar ciertas informaciones, aún cuando no existan peticiones sobre las mismas.
            </p>

            <p class="semibold-text text-white medium-text">
              La <a href="https://www.alianzaregional.net/" target="_blank">Alianza Regional por la libre
                expresión e información</a> trae una dimensión necesaria y poco estudiada para comprender el grado de
              avance (o no) del AIP en la región: <span class="green-text bold-text">el análisis sobre la situación de
                la
                transparencia activa de los sistemas judiciales de 15 países de la región, observando el comportamiento
                en todos sus componentes:</span>
            <ol class="green-text bold-text">
              <li>el Poder Judicial, las fiscalías, y defensorías;</li>
              <li>las agencias de selección, evaluación, disciplina y remoción.</li>
            </ol>
            </p>
            <report-buttons></report-buttons>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6 d-none d-lg-block">
        <div v-animate-css="{ classes: 'bounceInRight', duration: 1500 }" class="circle-1 d-none d-lg-block">
        </div>
        <img v-animate-css="animationLupa" slot="image" class="d-none d-lg-block"
          style="position: absolute; right: 0; top: 10%; width: 500px" src="img/resources/lupa-hand.png" />

        <div v-animate-css="{ classes: 'bounceInUp', duration: 1500 }" class="circle-2 d-none d-lg-block"></div>
        <img v-animate-css="animationLaptop" slot="image" class="laptop-img d-none d-lg-block"
          style="position: absolute; left: 0; bottom: 5px; width: 350px" src="img/resources/laptop.png" />
      </div>
    </div>
  </section>
</template>
<script>
import { ReportButtons } from "@/components";

export default {
  name: 'BannerSection',
  components: {
    ReportButtons
  },
  data() {
    return {
      animationLupa: {
        classes: "bounceInRight",
        duration: 1500,
        delay: 200,
      },
      animationLaptop: {
        classes: "bounceInUp",
        duration: 1500,
        delay: 200,
      }
    }
  },

}
</script>
<style lang="scss">
.inside-image-box {
  position: absolute;
  top: 0;
}

.banner-title-text {
  font-family: Montserrat-Black;
  font-size: 3em;
}

.banner-subtitle-text {
  color: var(--rose-color);
  font-family: Montserrat-Bold;
  font-size: 1.8em;
  line-height: 1em;
  letter-spacing: 0.1em;
}

.circle-1 {
  height: 400px;
  width: 400px;
  position: absolute;
  right: 120px;
  top: 9%;
  background-color: #5eb5ab;
  border-radius: 50%;
  display: inline-block;
}

.circle-2 {
  position: absolute;
  height: 350px;
  width: 350px;
  bottom: 80px;
  left: 5px;
  background-color: #991d44;
  border-radius: 50%;
  display: inline-block;
}

@media (max-width: 768px) {
  .circle-2 {
    left: 50% !important;
    margin-left: -175px;
  }

  .content-banner {
    margin: 1rem 12% !important;
  }

  .logo-alianza {
    justify-content: flex-end !important;
  }

  .banner-height {
    //min-height: 1100px !important;
    margin-bottom: 50px;
  }

  .laptop-img {
    left: 50% !important;
    margin-left: -175px !important;
  }

  @media (max-width: 768px) {
    .mobile-banner-height {
      min-height: 1477px !important;
    }
  }
}
</style>
