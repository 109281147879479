<template>
  <div class="d-flex justify-content-center align-items-center embed-responsive embed-responsive-1by1 gray-bg">
    <div v-if="isLoading" class="loading-indicator">Cargando...</div>
    <!-- <video v-show="!isLoading" ref="videoPlayer" width="100%" height="100%"
            :src="videoSrc" loop muted autoplay></video> -->
    <video ref="videoPlayer" :src="videoSrc" width="100%" height="100%" muted loop playsinline></video>
  </div>
</template>

<script>
export default {
  name: "VideoCustom",
  props: {
    videoSrc: {
      type: String,
      default: "#",
      description: "Src video source",
    },
  },
  data() {
    return {
      isLoading: true,
      isVideoInView: false,
      observer: null,
    };
  },
  mounted() {
    this.setupObserver();
  },
  updated() {
    this.setupObserver();
  },
  methods: {
    playVideo() {
      if (this.$refs.videoPlayer.paused) {
        this.$refs.videoPlayer.play();
      }
    },
    pauseVideo() {
      if (!this.$refs.videoPlayer.paused) {
        this.$refs.videoPlayer.pause();
      }
    },
    isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    setupObserver() {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((e) => {
            if (e.isIntersecting) {
              // console.log("play");
              //   e.target.play();
              this.playVideo();
            } else {
              // console.log("pause");
              this.pauseVideo();
            }
          });
        },
        { root: null, threshold: 0.5, rootMargin: "0px" }
      );
      this.observer.observe(this.$refs.videoPlayer);
    },
  },
  watch: {
    videoSrc() {
      //   this.isLoading = true;
    },
  },
  beforeDestroy() {
    // Clean up the observer when the component is destroyed
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  },
};
</script>

<style scoped>
.loading-indicator {
  color: white;
  font-weight: bold;
}
</style>
